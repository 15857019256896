import { faBalanceScale } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import Layout from "../components/layout"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import Seo from "../components/seo"

const Sell = ({ location }) => {
  return (
    <Layout location={location}>
      <Seo title="不動産の売却" description="株式会社ファーストの不動産売却" />
      <div className="container">
        <div
          style={{
            textAlign: "center",
            fontSize: "2rem",
            color: "gray",
            margin: "10px 0px",
          }}
        >
          <FontAwesomeIcon icon={faBalanceScale} />
        </div>
        <h3 className="title is-4" style={{ color: "gray" }}>
          不動産の売却
        </h3>
        <section className="section">
          <h4 className="title is-4">ニーズに沿った売却提案</h4>
          <p>
            当社は居住用住宅から投資・事業用物件まで不動産全般の売買仲介、買取を承っております。
          </p>
          <p className="block">
            物件特性やお客様のニーズに合わせて、「仲介売買」か「当社買取」を提案させていただきます。
          </p>
          <div className="columns is-centered">
            <div className="column is-half">
              <StaticImage
                src="../images/flows/flow-sell-agent.drawio.png"
                style={{ margin: "0 auto" }}
                placeholder="none"
                quality={90}
                alt=""
              />
            </div>
            <div className="column is-half">
              <StaticImage
                src="../images/flows/flow-sell-purchase.drawio.png"
                style={{ margin: "0 auto" }}
                placeholder="none"
                quality={90}
                alt=""
              />
            </div>
          </div>
          <div style={{ maxWidth: "1000px", margin: "0 auto" }}>
            <table className="table is-fullwidth is-bordered table-company">
              <tr>
                <th>仲介売買の特徴</th>
                <td>
                  購入希望者へ売却します。
                  <br />
                  平均3ヵ月程度を要しますが、高い金額で売却できる可能性があります。
                  <br />
                  なお、売主は瑕疵担保責任を負います。
                </td>
              </tr>
              <tr>
                <th>当社買取の特徴</th>
                <td>
                  当社が買い取ります。
                  最短10日～1ヵ月程度で売却できますが、実勢価格の7割程度の買取価格となります。
                  <br />
                  売主は瑕疵担保責任を負いません。
                </td>
              </tr>
            </table>
          </div>
          <p className="mt-2">
            一般的に、ご自身の不動産売却は何度も経験することではありません。実際なにをしたらよいかなど、ご不明・ご不安な点があることと存じます。
          </p>
          <p className="block">
            当社スタッフがお客様に合った最善な方法をご提案させていただきますので、是非ご相談ください。
          </p>
          <Link
            to="/contact"
            className="button is-dark is-fullwidth my-4 is-size-5"
            style={{ height: "4em" }}
          >
            お問い合わせはこちら　＞
          </Link>
        </section>
      </div>
    </Layout>
  )
}

export default Sell
